import * as bootstrap from 'bootstrap';

window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  // shrinking navi
  if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
    document.querySelector("#logo").classList.add("shrink");
    document.querySelector(".navbar").classList.add("shrink");
  } else {
    document.querySelector("#logo").classList.remove("shrink");
    document.querySelector(".navbar").classList.remove("shrink");
  }

  // donation button
  if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
    const donationButton = document.querySelector(".m-donation-button")
    if (donationButton) {
      donationButton.classList.add("show");
    }
  } else {
    const donationButton = document.querySelector(".m-donation-button")
    if (donationButton) {
      donationButton.classList.remove("show");
    }
  }
}

document.querySelectorAll(".m-cards .card").forEach(c => {
  c.addEventListener("mouseover", e => {
    c.querySelector(".card-img-overlay").classList.remove("hide");
  });
  c.addEventListener("mouseleave", e => {
    c.querySelector(".card-img-overlay").classList.add("hide");
  });
});

document.querySelectorAll(".m-donation-button").forEach(c => {  
  c.addEventListener("mouseover", e => {
    document.querySelector(".m-donation-button_info").classList.add("show");
  });
  c.addEventListener("mouseleave", e => {
    document.querySelector(".m-donation-button_info").classList.remove("show");
  });
});

document.querySelectorAll(".readmorecontainer").forEach(readmorecontainer => {
  readmorecontainer.querySelectorAll(".readmorebox").forEach(readmorebox => {
    let height = 0;
    readmorebox.querySelectorAll("p,h2,div").forEach(p => {
      height += p.clientHeight;
    });    
  
    readmorecontainer.querySelectorAll(".readmore").forEach(readmorelink => {
      readmorelink.addEventListener("click", e => {
        readmorebox.style.height = height + "px";
        readmorebox.style.maxHeight = "9999px";
        readmorebox.querySelector(".fader").classList.add("hide");
        
        readmorelink.classList.add("hide");
  
        e.preventDefault();
      });
    });
  });
});

document.querySelectorAll(".btn-open-link").forEach(link => {
  link.addEventListener("click", e => {
  if (window.confirm("Sie verlassen jetzt die Internetpräsenz des Hebammenhauses Rhein-Sieg. Für den Inhalt der Website, auf die Sie weitergeleitet werden, ist ausschließlich deren Betreiber verantwortlich und es gelten dessen Geschäftsbedingungen und Richtlinien, insbesondere zum Datenschutz. Sind Sie damit einverstanden?")) {
    window.open(e.target.dataset.url, "_blank").focus();
  }
  e.preventDefault();
})});
